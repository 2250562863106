import { createLazyPage } from "lib/lazyRouteUtils";
import { lazy } from "react";

const Ships = lazy(() =>
  import("../Ships").then((module) => ({
    default: module.Ships,
  }))
);
const Ship = lazy(() =>
  import("../components/Ship").then((module) => ({
    default: module.Ship,
  }))
);

export const LazyShips = createLazyPage(Ships);
export const LazyShip = createLazyPage(Ship);
