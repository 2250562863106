import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { shipRouteAppHeaderConfig } from "./shipRouteAppHeaderConfig";
import { LazyShip, LazyShips } from "./lazyShipsRoutes";

export const shipRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: LazyShips,
  ...shipRouteAppHeaderConfig,
});

const specificShipRoute = createRoute({
  getParentRoute: () => shipRoute,
  component: LazyShip,
  path: "$pageId",
});

shipRoute.addChildren([specificShipRoute]);
