import alchemist from "./alchemist.png";
import amberclad from "./amberclad.png";
import amberedIrons from "./amberedIrons.png";
import anchored from "./anchored.png";
import ardent from "./ardent.png";
import armaments from "./armaments.png";
import attackRoll from "./attackRoll.png";
import bloodmawHunters from "./bloodmawHunters.png";
import celebration from "./celebration.png";
import char from "./char.png";
import characterSheet from "./characterSheet.png";
import charts from "./charts.png";
import chase from "./chase.png";
import chatting from "./chatting.png";
import companion from "./companion.png";
import concoct from "./concoct.png";
import corron from "./corron.png";
import corron2 from "./corron-2.png";
import corsair from "./corsair.png";
import crash from "./crash.png";
import crawler from "./crawler.png";
import dawhehWhe from "./dawheh_wheh.png";
import dawhehWhe2 from "./dawheh_wheh2.png";
import design from "./design.png";
import dredger from "./dredger.png";
import drives from "./drives.png";
import ektus from "./ektus.png";
import food from "./food.png";
import foxloft from "./foxloft.png";
import gangs from "./gangs.png";
import gau from "./gau.png";
import hacker from "./hacker.png";
import hazards from "./hazards.png";
import horizoneer from "./horizoneer.png";
import hunter from "./hunter.png";
import icterine from "./icterine.png";
import impact from "./impact.png";
import interregnum from "./interregnum.png";
import ironbound from "./ironbound.png";
import ironjawRays from "./ironjawRays.png";
import ironroots from "./ironroots.png";
import ketra from "./ketra.png";
import kremichSurge from "./kremichSurge.png";
import languages from "./languages.png";
import lantern from "./lantern.png";
import lionsMane from "./lionsMane.png";
import lore from "./lore.png";
import madWoodcutter from "./madWoodcutter.png";
import manticrows from "./manticrows.png";
import mantidDrake from "./mantidDrake.png";
import marauders from "./marauders.png";
import mesmer from "./mesmer.png";
import monster from "./monster.png";
import mothryn from "./mothryn.png";
import mothryn2 from "./mothryn2.png";
import navigator from "./navigator.png";
import navigator2 from "./navigator2.png";
import octodew from "./octodew.png";
import octopus from "./octopus.png";
import oldOrnail from "./oldOrnail.png";
import orchidScar from "./orchidScar.png";
import outrider from "./outrider.png";
import pangoska from "./pangoska.png";
import pinwolf from "./pinwolf.png";
import queenBeneath from "./queenBeneath.png";
import range from "./range.png";
import rating from "./rating.png";
import rattlehand from "./rattlehand.png";
import relax from "./relax.png";
import resources from "./resources.png";
import ridgeback from "./ridgeback.png";
import rootless from "./rootless.png";
import saws from "./saws.png";
import screw from "./screw.png";
import shankling from "./shankling.png";
import ship from "./ship.png";
import ship2 from "./ship2.png";
import ship3 from "./ship3.png";
import ship4 from "./ship4.png";
import shipbuilding from "./shipbuilding.png";
import shipgarden from "./shipgarden.png";
import shipSheet from "./shipSheet.png";
import slinger from "./slinger.png";
import slinger2 from "./slinger2.png";
import slinger3 from "./slinger3.png";
import snapperpillars from "./snapperpillars.png";
import species from "./species.png";
import spectrapedes from "./spectrapedes.png";
import spirituality from "./spirituality.png";
import spitBorn from "./spitBorn.png";
import steep from "./steep.png";
import surgeon from "./surgeon.png";
import tempest from "./tempest.png";
import terminology from "./terminology.png";
import terrapex from "./terrapex.png";
import tracks from "./tracks.png";
import travel from "./travel.png";
import tryphoradons from "./tryphoradons.png";
import tzelicrae from "./tzelicrae.png";
import watch from "./watch.png";
import whitecollarSnare from "./whitecollarSnare.png";
import wildsailor from "./wildsailor.png";
import winterJackets from "./winterJackets.png";
import wordbearer from "./wordbearer.png";
import wretchbeaks from "./wretchbeaks.png";

export {
  alchemist,
  amberclad,
  amberedIrons,
  anchored,
  ardent,
  armaments,
  attackRoll,
  bloodmawHunters,
  celebration,
  char,
  characterSheet,
  charts,
  chase,
  chatting,
  companion,
  concoct,
  corron,
  corron2,
  corsair,
  crash,
  crawler,
  dawhehWhe,
  dawhehWhe2,
  design,
  dredger,
  drives,
  ektus,
  food,
  foxloft,
  gangs,
  gau,
  hacker,
  hazards,
  horizoneer,
  hunter,
  icterine,
  impact,
  interregnum,
  ironbound,
  ironjawRays,
  ironroots,
  ketra,
  kremichSurge,
  languages,
  lantern,
  lionsMane,
  lore,
  madWoodcutter,
  manticrows,
  mantidDrake,
  marauders,
  mesmer,
  monster,
  mothryn,
  mothryn2,
  navigator,
  navigator2,
  octodew,
  octopus,
  oldOrnail,
  orchidScar,
  outrider,
  pangoska,
  pinwolf,
  queenBeneath,
  range,
  rating,
  rattlehand,
  relax,
  resources,
  ridgeback,
  rootless,
  saws,
  screw,
  shankling,
  ship,
  ship2,
  ship3,
  ship4,
  shipbuilding,
  shipgarden,
  shipSheet,
  slinger,
  slinger2,
  slinger3,
  snapperpillars,
  species,
  spectrapedes,
  spirituality,
  spitBorn,
  steep,
  surgeon,
  tempest,
  terminology,
  terrapex,
  tracks,
  travel,
  tryphoradons,
  tzelicrae,
  watch,
  whitecollarSnare,
  wildsailor,
  winterJackets,
  wordbearer,
  wretchbeaks,
};

export const COMPENDIUM_IMAGES = {
  alchemist,
  amberclad,
  amberedIrons,
  anchored,
  ardent,
  armaments,
  attackRoll,
  bloodmawHunters,
  celebration,
  char,
  characterSheet,
  charts,
  chase,
  chatting,
  companion,
  concoct,
  corron,
  corron2,
  corsair,
  crash,
  crawler,
  dawhehWhe,
  dawhehWhe2,
  design,
  dredger,
  drives,
  ektus,
  food,
  foxloft,
  gangs,
  gau,
  hacker,
  hazards,
  horizoneer,
  hunter,
  icterine,
  impact,
  interregnum,
  ironjawRays,
  ironbound,
  ironroots,
  ketra,
  kremichSurge,
  languages,
  lantern,
  lionsMane,
  lore,
  madWoodcutter,
  manticrows,
  mantidDrake,
  marauders,
  mesmer,
  monster,
  mothryn,
  mothryn2,
  navigator,
  navigator2,
  octodew,
  octopus,
  oldOrnail,
  orchidScar,
  outrider,
  pangoska,
  pinwolf,
  queenBeneath,
  range,
  rating,
  rattlehand,
  relax,
  resources,
  ridgeback,
  rootless,
  saws,
  screw,
  shankling,
  ship,
  ship2,
  ship3,
  ship4,
  shipbuilding,
  shipgarden,
  shipSheet,
  slinger,
  slinger2,
  slinger3,
  snapperpillars,
  species,
  spectrapedes,
  spirituality,
  spitBorn,
  steep,
  surgeon,
  tempest,
  terminology,
  terrapex,
  tracks,
  travel,
  tryphoradons,
  tzelicrae,
  watch,
  whitecollarSnare,
  wildsailor,
  winterJackets,
  wordbearer,
  wretchbeaks,
};
