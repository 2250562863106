import { createLazyPage } from "lib/lazyRouteUtils";
import { lazy } from "react";

const Characters = lazy(() =>
  import("../Characters").then((module) => ({
    default: module.Characters,
  }))
);
const Character = lazy(() =>
  import("../Character").then((module) => ({
    default: module.Character,
  }))
);
const QuickstartCharacterWizard = lazy(() =>
  import(
    "../components/Wizard/QuickstartCharacterWizard/QuickstartCharacterWizard"
  ).then((module) => ({
    default: module.QuickstartCharacterWizard,
  }))
);

export const LazyCharacters = createLazyPage(Characters);
export const LazyCharacter = createLazyPage(Character);
export const LazyQuickstartCharacterWizard = createLazyPage(
  QuickstartCharacterWizard
);
