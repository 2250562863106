import { lazy } from "react";
import { createLazyPage } from "lib/lazyRouteUtils";

const GameMasters = lazy(() =>
  import("../GameMasters").then((module) => ({
    default: module.GameMasters,
  }))
);
const GameMaster = lazy(() =>
  import("../GameMaster").then((module) => ({
    default: module.GameMaster,
  }))
);
export const LazyGameMasters = createLazyPage(GameMasters);
export const LazyGameMaster = createLazyPage(GameMaster);
