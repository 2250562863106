import { values } from "lodash";
import {
  CloudLightning,
  PlaneLanding,
  PlaneTakeoff,
  Telescope,
} from "lucide-react";

export const EXPANSIONS = {
  SAR: "SAR",
  SAR_AIR: "SAR_AIR",
  SAR_SUB: "SAR_SUB",
  BASE_REACHES: "BASE_REACHES",
};

export const EXPANSIONS_VALUES = values(EXPANSIONS);

export const EXPANSIONS_CONFIG = {
  [EXPANSIONS.SAR]: {
    label: "Storm and Root",
    logo: CloudLightning,
  },
  [EXPANSIONS.SAR_AIR]: {
    label: "Storm and Root - Airship",
    logo: PlaneTakeoff,
  },
  [EXPANSIONS.SAR_SUB]: {
    label: "Storm and Root - Submarine",
    logo: PlaneLanding,
  },
  [EXPANSIONS.BASE_REACHES]: {
    label: "Reaches (Base)",
    logo: Telescope,
  },
};
