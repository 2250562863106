import { EmptyPageSection } from "components/Common/Section/EmptyPageSection";
import { Suspense } from "react";

export const createLazyPage =
  (Component, { loadingLabel = "page", ...rest } = {}) =>
  (props) => (
    <Suspense
      fallback={
        <EmptyPageSection label="Please wait a moment" {...rest}>
          Loading {Component.displayName || loadingLabel}
        </EmptyPageSection>
      }
    >
      <Component {...props} />
    </Suspense>
  );
