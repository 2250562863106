import { Modal } from "components/Common/Modal";
import styles from "./tutorialModal.module.scss";
import { Button } from "components/Common/Button";
import classNames from "classnames/bind";

const classNameBuilder = classNames.bind(styles);

export const TutorialModal = ({
  portalProps,
  title,
  subtitle,
  buttonLabel = "NEXT",
  onButtonClick,
  className,
  buttonProps,
}) => {
  return (
    <Modal
      portalProps={portalProps}
      className={styles["modal-container"]}
      contentClassName={classNameBuilder("modal", className)}
      closable={false}
      header={null}
    >
      <div className={styles["container"]}>
        <div className={styles["gradient"]} />
        <div className={styles["paragraph"]}>
          <div className={styles["paragraph-title"]}>{title}</div>
          <div className={styles["paragraph-content"]}>{subtitle}</div>
        </div>
        <Button colorful large onClick={onButtonClick} {...buttonProps}>
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
};
