import { createLazyPage } from "lib/lazyRouteUtils";
import { lazy } from "react";

const Groups = lazy(() =>
  import("../Groups").then((module) => ({
    default: module.Groups,
  }))
);

const Group = lazy(() =>
  import("../Group").then((module) => ({
    default: module.Group,
  }))
);

export const LazyGroups = createLazyPage(Groups);
export const LazyGroup = createLazyPage(Group);
