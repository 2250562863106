import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { compendiumAppHeaderConfig } from "./compendiumAppHeaderConfig";
import { COMPENDIUM_PATHS } from "./routeConsts";
import {
  LazyCompendium,
  LazyCompendiumHome,
  LazyCompendiumPage,
  LazyCompendiumSearchResults,
} from "./lazyCompendiumRoutes";

export const compendiumRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: LazyCompendium,
  notFoundComponent: () => (
    <div>
      <h1 style={{ margin: 0 }}>Page not found!</h1>
    </div>
  ),
  pendingMinMs: 1000,
  ...compendiumAppHeaderConfig,
});

const compendiumSearchRoute = createRoute({
  getParentRoute: () => compendiumRoute,
  component: LazyCompendiumSearchResults,
  path: `${COMPENDIUM_PATHS.SEARCH}/$`,
});

const compendiumEntryRoute = createRoute({
  getParentRoute: () => compendiumRoute,
  component: LazyCompendiumPage,
  path: `${COMPENDIUM_PATHS.ENTRY}/$`,
});

const compendiumIndex = createRoute({
  getParentRoute: () => compendiumRoute,
  component: LazyCompendiumHome,
  path: "/",
});

compendiumRoute.addChildren([
  compendiumIndex,
  compendiumEntryRoute,
  compendiumSearchRoute,
]);
