import { Fragment } from "react";

export const generateSpacedError = (errs) => (
  <span>
    {errs.map((e, i) => {
      const suffix =
        i !== errs.length - 1 ? [<br key={"br1"} />, <br key={"br2"} />] : null;

      return (
        <Fragment key={i}>
          {e}
          {suffix}
        </Fragment>
      );
    })}
  </span>
);

const AUTH_ERRORS = {
  WRONG_PASSWORD: "auth/wrong-password",
  INVALID_EMAIL: "auth/invalid-email",
  MISSING_PASSWORD: "auth/missing-password",
  USER_NOT_FOUND: "auth/user-not-found",
  POPUP_CLOSED: "auth/popup-closed-by-user",
  POPUP_BLOCKED: "auth/popup-blocked",
  REDIRECT_BLOCKED: "auth/redirect-cancelled-by-user",
  WEAK_PASSWORD: "auth/weak-password",
  EMAIL_ALREADY_IN_USE: "auth/email-already-in-use",
  MISSING_EMAIL: "auth/missing-email",
  TIMEOUT: "auth/timeout",
  TOO_MANY_ATTEMPTS: "auth/too-many-requests",
  USER_DISABLED: "auth/user-disabled",
  WEB_STORAGE_UNSUPPORTED: "auth/web-storage-unsupported",
  INVALID_CREDENTIAL: "auth/invalid-credential",
};

export const EMAIL_SIGN_IN_ERRORS = {
  [AUTH_ERRORS.WRONG_PASSWORD]: {
    label:
      "The provided email/password combination couldn't be found. Please try again.",
  },
  [AUTH_ERRORS.INVALID_CREDENTIAL]: {
    label:
      "The provided email/password combination couldn't be found. Please try again.",
  },
  [AUTH_ERRORS.INVALID_EMAIL]: {
    label: "Invalid email provided.",
  },
  [AUTH_ERRORS.MISSING_PASSWORD]: {
    label: "Please provide a password.",
  },
  [AUTH_ERRORS.USER_NOT_FOUND]: {
    label:
      "The provided email/password combination couldn't be found. Please try again.",
  },
};

export const GOOGLE_SIGN_IN_ERRORS = {
  [AUTH_ERRORS.POPUP_CLOSED]: {
    label: "The authentication window was closed, please try again.",
  },
  [AUTH_ERRORS.POPUP_BLOCKED]: {
    label:
      "Popup was blocked! Please check your browser extensions and settings.",
  },
  [AUTH_ERRORS.REDIRECT_BLOCKED]: {
    label:
      "Redirect was blocked! Please check your browser extensions and settings.",
  },
};

export const EMAIL_CREATE_ERRORS = {
  [AUTH_ERRORS.WRONG_PASSWORD]: {
    label:
      "The email/password combo provided couldn't be found. Please try again.",
  },
  [AUTH_ERRORS.WEAK_PASSWORD]: {
    label: "Password should be at least 6 characters.",
  },
  [AUTH_ERRORS.INVALID_EMAIL]: {
    label: "Invalid email provided.",
  },
  [AUTH_ERRORS.MISSING_PASSWORD]: {
    label: "Please provide a password.",
  },
  [AUTH_ERRORS.EMAIL_ALREADY_IN_USE]: {
    label: generateSpacedError([
      "The email provided is already in use. Please try again with a different email.",
      "You may have signed in previously using Google Authentication",
      <span>
        If you did not previously create an account, please contact{" "}
        <u>charsmithapp@gmail.com</u> using the email in question and we can
        help investigate the account
      </span>,
    ]),
  },
};
export const RESET_PASSWORD_ERRORS = {
  [AUTH_ERRORS.MISSING_EMAIL]: {
    label: "Please provide an email.",
  },
  [AUTH_ERRORS.INVALID_EMAIL]: {
    label: "Invalid email provided.",
  },
  [AUTH_ERRORS.USER_NOT_FOUND]: {
    label: "User does not exist.",
  },
};

export const CAUGHT_AUTH_ERRORS = {
  [AUTH_ERRORS.TIMEOUT]: {
    label: generateSpacedError([
      "Uh oh, the authentication timed out! Please try again.",
      <span>
        If you continue to have issues, please contact our support email at{" "}
        <i>charsmithapp@gmail.com</i>.
      </span>,
    ]),
  },
  [AUTH_ERRORS.TOO_MANY_ATTEMPTS]: {
    label: "Too many attempts! Please try again later.",
  },
  [AUTH_ERRORS.USER_DISABLED]: {
    label: (
      <span>
        This user was disabled! Please contact <u>charsmithapp@gmail.com</u> for
        more information.
      </span>
    ),
  },
  [AUTH_ERRORS.WEB_STORAGE_UNSUPPORTED]: {
    label: generateSpacedError([
      "Web storage is not supported! Please check your browser settings.",
      <span>
        Contact <u>charsmithapp@gmail.com</u> if you need help resolving this
        issue.
      </span>,
    ]),
  },
  ...EMAIL_SIGN_IN_ERRORS,
  ...EMAIL_CREATE_ERRORS,
  ...GOOGLE_SIGN_IN_ERRORS,
  ...RESET_PASSWORD_ERRORS,
};

export const getErrorLabel = (error, preferred = {}) =>
  preferred[error?.code]?.label ||
  CAUGHT_AUTH_ERRORS[error?.code]?.label ||
  error?.code;
