import { createLazyPage } from "lib/lazyRouteUtils";
import { lazy } from "react";
import styles from "./lazyCompendiumRoutes.module.scss";

const BaseCompendium = lazy(() =>
  import("../Compendium").then((module) => ({
    default: module.BaseCompendium,
  }))
);
const Compendium = lazy(() =>
  import("../Compendium").then((module) => ({
    default: module.Compendium,
  }))
);
const CompendiumSearchResults = lazy(() =>
  import("../CompendiumSearchResults").then((module) => ({
    default: module.CompendiumSearchResults,
  }))
);
const CompendiumPage = lazy(() =>
  import("../CompendiumPage").then((module) => ({
    default: module.CompendiumPage,
  }))
);
const CompendiumHome = lazy(() =>
  import("../CompendiumHome").then((module) => ({
    default: module.CompendiumHome,
  }))
);
const StaticCompendiumRoutingProvider = lazy(() =>
  import("../contexts/StaticCompendiumRoutingProvider").then((module) => ({
    default: module.StaticCompendiumRoutingProvider,
  }))
);
export const LazyBaseCompendium = createLazyPage(BaseCompendium, {
  loadingLabel: "Compendium",
});
export const LazyCompendium = createLazyPage(Compendium, {
  loadingLabel: "Compendium",
});
export const LazyCompendiumSearchResults = createLazyPage(
  CompendiumSearchResults,
  {
    containerClassName: styles["empty-section"],
  }
);
export const LazyCompendiumPage = createLazyPage(CompendiumPage, {
  containerClassName: styles["empty-section"],
});
export const LazyCompendiumHome = createLazyPage(CompendiumHome, {
  containerClassName: styles["empty-section"],
});
export const LazyStaticCompendiumRoutingProvider = createLazyPage(
  StaticCompendiumRoutingProvider,
  {
    loadingLabel: "Compendium",
  }
);
