import {
  CHARACTER_WIZARD_PATH,
  CHARACTER_WIZARD_QUICKSTART_PATH,
} from "./charactersConsts";
import styles from "./charactersRoutes.module.scss";
import { Outlet, createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { characterRouteAppHeaderConfig } from "./characterRouteAppHeaderConfig";
import {
  LazyCharacters,
  LazyCharacter,
  LazyQuickstartCharacterWizard,
} from "./lazyCharactersRoutes";

const OutletForwarder = (props) => {
  return <Outlet {...props} />;
};

export const characterRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: LazyCharacters,
  ...characterRouteAppHeaderConfig,
});

const specificCharacterRoute = createRoute({
  getParentRoute: () => characterRoute,
  path: "$pageId",
  component: LazyCharacter,
  staticData: {
    label: "Character",
  },
});

export const characterWizardRoute = createRoute({
  getParentRoute: () => characterRoute,
  path: `${CHARACTER_WIZARD_PATH}`,
  component: OutletForwarder,
  staticData: {
    label: "Character Wizard",
    shouldHideHeaderPortal: true,
    shouldHideFooter: true,
    appClassName: styles["app"],
    contentClassName: styles["content"],
  },
});

const quickstartCharacterWizardRoute = createRoute({
  getParentRoute: () => characterWizardRoute,
  path: CHARACTER_WIZARD_QUICKSTART_PATH,
  component: LazyQuickstartCharacterWizard,
  staticData: {
    label: "Quickstart Character Wizard",
  },
});

characterWizardRoute.addChildren([quickstartCharacterWizardRoute]);
characterRoute.addChildren([characterWizardRoute, specificCharacterRoute]);
