import styles from "./emptyPageSection.module.scss";
import { Loader } from "../Loader";
import { Section } from "../Section";
import classNames from "classnames/bind";
import { useEffect, useRef, useState } from "react";

const classNameBuilder = classNames.bind(styles);

export const EmptyPageSection = ({
  icon = <Loader />,
  label,
  children = "Loading...",
  containerClassName,
  contentContainerClassName,
  textContainerClassName,
  headerSuffix,
  underSection,
  delayedContent,
  delayedContentTimeout,
  delayedContentClassName,
}) => {
  const timerRef = useRef();
  const [showDelayed, setShowDelayed] = useState(false);

  useEffect(() => {
    if (delayedContentTimeout) {
      timerRef.current = setTimeout(
        () => setShowDelayed(true),
        delayedContentTimeout
      );
    }

    return () => clearTimeout(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section
      className={classNameBuilder("container", containerClassName)}
      label={label}
      headerSuffix={headerSuffix}
      underSection={underSection}
    >
      <div
        className={classNameBuilder(
          "content-container",
          contentContainerClassName,
          {
            [delayedContentClassName]: showDelayed,
          }
        )}
      >
        {icon && !showDelayed && (
          <div className={classNameBuilder("icon-container")}>{icon}</div>
        )}
        <div
          className={classNameBuilder("text-container", textContainerClassName)}
        >
          {showDelayed ? delayedContent : children}
        </div>
      </div>
    </Section>
  );
};
