import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { groupRouteAppHeaderConfig } from "./groupRouteAppHeaderConfig";
import { LazyGroup, LazyGroups } from "./lazyGroupsRoutes";

export const groupRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: LazyGroups,
  ...groupRouteAppHeaderConfig,
});

const specificGroupRoute = createRoute({
  getParentRoute: () => groupRoute,
  component: LazyGroup,
  path: "$pageId",
  staticData: {
    label: "Group",
  },
});

groupRoute.addChildren([specificGroupRoute]);
