import { MessageSquareText, TelescopeIcon } from "lucide-react";
import styles from "./changeLog.module.scss";
import { BULLET_LIST_ITEM_TYPE } from "components/Common/List/bulletListConsts";
import { stableLink, supportEmail } from "constants/contactInfo";

export const versions = [
  {
    version: "1.4.2",
    date: "2024-09-16",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Added ability to print the app using CTRL + P",
        items: [
          "A small window with a screencap of the app in a light gray theme will open.",
          "Click the 'Download' button to download the image to your computer.",
          "If you press CTRL + SHIFT + P, the screencap will use your existing themes",
          "There may be some inconsistent stylings in the screencap, but unfortunately this is a limitation of the library we are using",
        ],
      },
      "Keybinds can always be found again at the bottom of the Settings Drawer",
    ],
  },
  {
    version: "1.4.1",
    date: "2024-09-14",
    changes: [
      "Added icon and banner to compendium articles from expansions",
      "Bugfix for dynamic links in compendium articles",
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: 'Added "Expansion Articles" setting',
        items: [
          'Found under the "Compendium" section of the Settings Drawer',
          "If toggled on, all articles from all expansions will be visible in the compendium",
          "If toggled off, only articles from expansions enabled in your current group will be visible.",
          "If no group is selected, no expansions are enabled",
          "Setting is toggled off by default",
        ],
      },
    ],
  },
  {
    version: "1.4.0",
    date: "2024-09-14",
    changes: [
      "Added loading screen when creating a new Group, Character, Ship or GM.",
      "Bugfixes related to loading invalid sheets",
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Modified routing architecture",
        items: [
          {
            type: BULLET_LIST_ITEM_TYPE.WARNING,
            text: (
              <span>
                If you encounter problems with the new app routing, please
                notify us at <u>{supportEmail}</u> and use the{" "}
                <a href={stableLink}>stable version</a> of the site in the
                meantime
              </span>
            ),
          },
        ],
      },
    ],
  },
  {
    version: "1.3.0",
    date: "2024-09-12",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: 'Added "Expansions" section to the Groups page',
        items: [
          'The toggle to enable expansions is located in the "Group Info" section. It is only modifiable by an Owner.',
          'The first "expansion" we\'re supporting is the Reaches from the base Wildsea book.',
          "This includes 2 bloodlines (Corron and Dawheh-Whe) and some ship Design options",
          <span>
            Content from an expansion will often be accompanied by its
            corresponding logo. For example, the Reaches (Base) logo is{" "}
            <TelescopeIcon
              style={{ height: "18px", width: "18px", marginBottom: "-2px" }}
            />
          </span>,
        ],
      },
    ],
  },
  {
    version: "1.2.3",
    date: "2024-09-11",
    changes: [
      "Bugfixes in Character Creator and Compendium",
      "Updated stable version to v1.2.1",
    ],
  },
  {
    version: "1.2.2",
    date: "2024-08-25",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added ability to export sheets to JSON.",
        items: [
          "Try it out by clicking CTRL + S while on any character, ship, or GM.",
          "You can find this and other useful keybinds at the bottom of the settings drawer.",
        ],
      },
    ],
  },
  {
    version: "1.2.1 [STABLE]",
    date: "2024-08-21",
    changes: [
      "Added UI in dice roller for adding cut to a roll.",
      "Re-organized settings drawer.",
      "Added several settings to let users customize their dice, cut, and chat behavior.",
    ],
  },
  {
    version: "1.2.0",
    date: "2024-08-12",
    changes: [
      <span>
        Added ability to ping certain sections of sheets into the chat! Look for
        the <MessageSquareText width={15} height={15} /> icon in your sheets!
      </span>,
      "Added feature to allow new accounts to set up their profile settings immediately after creation",
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: "Added new login method: email + password ",
        items: [
          `If you'd like to add a password to an existing account, click the "Forgot password?" link on the sign-in page and follow the on-screen instructions.`,
          `gmail-based "email + password" accounts will still be able to use the "Sign in with Google" button`,
          {
            type: BULLET_LIST_ITEM_TYPE.WARNING,
            text: `If you use a fake email, we will not be able to reset your password!`,
          },
          <span>
            If you run into any login issues, please contact{" "}
            <u>charsmithapp@gmail.com</u>
          </span>,
        ],
      },
    ],
  },
  {
    version: "1.1.1",
    date: "2024-08-12",
    changes: ["Added 2 new themes: Pink (Dark) and Pink (Light)"],
  },
  {
    version: "1.1.0",
    date: "2024-08-09",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.WARNING,
        text: (
          <>
            <span>
              Updated Site Routing Architecture - while big for the dev team,
              you will hopefully notice no changes in behavior
            </span>
            <br />
            <br />
            <div>
              If you notice any issues, please send an email to{" "}
              <u>charsmithapp@gmail.com</u>,
            </div>
          </>
        ),
      },
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: (
          <>
            <span>
              We've created a 'stable' version of the app with the last stable
              version of the app. If you are unable to use the app due to an
              update, try using{" "}
              <a href={stableLink} className={styles["link"]}>
                {stableLink}
              </a>
            </span>
            <br />
            <br />
            <div>The stable build of the site will be on version 1.0.2</div>
          </>
        ),
      },
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added Mute Button - our first patreon request! 🎉",
      },
      "Bugfixes",
    ],
  },
  {
    version: "1.0.2",
    date: "2024-07-22",
    changes: [
      "Added ability to mark ratings in the ship sheet",
      'Added "By Firefly Light" logo to footer',
      "Added confirmation modal when deleting users from groups",
      "Added ability to remove yourself from a group, even if you're not the owner (available on the Groups page)",
      "Added confirm button when joining a group",
      'Added "Clear local storage and refresh" button on error page to help stuck users',
      "Bugfixes",
    ],
  },
  {
    version: "1.0.1",
    date: "2024-06-24",
    changes: [
      'Added this "Changelog" 🙂',
      'Fixed functionality of "ratings" in the ship sheet to match how they should be used in the rules',
      "Added ability to select Wildsailor portraits in the User section of the Settings Drawer",
      "Bugfixes",
    ],
  },
  {
    version: "1.0.0",
    date: "2024-06-19",
    changes: ["Initial launch! 🎉"],
  },
];

export const currentVersion = versions[0].version;
