import { Outlet, useLocation } from "@tanstack/react-router";
import { NavigatorProvider } from "../NavigatorContext";
import { AppHeader } from "components/Header/AppHeader";
import { Tutorial } from "components/Tutorial/Tutorial";
import { TooltipProvider } from "contexts/TooltipContext";
import { Footer } from "components/Footer/Footer";
import { useTheme } from "contexts/ThemeContext";
import { useEscapeBlur } from "hooks/listeners/useEscapeBlur";
import { useEffect, useRef, useState } from "react";
import { usePageConfig } from "hooks/usePageConfig";
import { useForceScrollToTop } from "components/Common/hooks/useForceScrollToTop";
import classnames from "classnames/bind";
import styles from "./root.module.scss";
import { Helmet } from "react-helmet";
import { TooltipStyleProvider } from "contexts/TooltipStyleContext";
import { Home } from "components/Home/Home";
import { IFrameProvider } from "contexts/IframeContext";
import { ErrorBoundary } from "react-error-boundary";
import { CrashPage } from "CrashPage";
import { isError, noop } from "lodash";
import { InitialLoadChangeLog } from "components/ChangeLog/ChangeLog";
import { useAuth } from "contexts/AuthContext";
import html2canvas from "html2canvas";
import { useKeydownListener } from "hooks/listeners/useKeydownListener";
import { Modal } from "components/Common/Modal";
import { usePortal } from "contexts/PortalContext";
import { Image } from "components/Common/Image/Image";
import { Button } from "components/Common/Button";
import { Download } from "lucide-react";

const classNameBuilder = classnames.bind(styles);

const RootContent = ({
  contentRef,
  screenshotPortalProps,
  setScreenshotStyle,
}) => {
  const [appClassName, contentClassName] = usePageConfig([
    "appClassName",
    "contentClassName",
  ]);
  const { shouldSetupUser } = useAuth();
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    if (screenshotPortalProps.isPortalComponentRendered) {
      html2canvas(document.querySelector("#capture2image"), {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight,
      })
        .then((canvas) => {
          setScreenshot(canvas.toDataURL());
        })
        .catch((e) => {
          console.error(e);
          setScreenshot(e);
        });
    }
  }, [screenshotPortalProps.isPortalComponentRendered]);

  useKeydownListener(
    (e) => {
      if (
        !screenshotPortalProps.isPortalComponentRendered &&
        e.code === "KeyP" &&
        e.ctrlKey
      ) {
        e.preventDefault();
        setScreenshot(null);

        if (e.shiftKey) {
          setScreenshotStyle("normal");
        } else {
          setScreenshotStyle("gray");
        }

        screenshotPortalProps.setIsPortalComponentRendered(true);
      }
    },
    [screenshotPortalProps.isPortalComponentRendered]
  );

  return (
    <TooltipStyleProvider scrollRef={contentRef}>
      <div
        className={classNameBuilder("scrollable", appClassName)}
        ref={contentRef}
      >
        {screenshotPortalProps.isPortalComponentRendered && screenshot && (
          <Modal title={"Screenshot"} portalProps={screenshotPortalProps}>
            {isError(screenshot) ? (
              <div>Failed to take screenshot. Please try again</div>
            ) : (
              <div className={styles["screenshot-content"]}>
                <Image
                  src={screenshot}
                  className={styles["screenshot-image"]}
                  contain
                />
                <a
                  download={`${Date.now()}-wildsea-screenshot`}
                  href={screenshot}
                  title="Download Screenshot"
                >
                  <Button className={styles["screenshot-button"]}>
                    <Download /> Download
                  </Button>
                </a>
              </div>
            )}
          </Modal>
        )}
        <div className={classNameBuilder("content", contentClassName)}>
          {!shouldSetupUser && (
            <>
              <Tutorial />
              <InitialLoadChangeLog />
            </>
          )}
          <TooltipProvider className={styles["tooltip-provider"]}>
            <Home>
              <Outlet />
            </Home>
          </TooltipProvider>
        </div>
        <Footer />
      </div>
    </TooltipStyleProvider>
  );
};

export const Root = () => {
  const { themeClassNames, theme } = useTheme();
  useEscapeBlur();
  const location = useLocation();
  const contentRef = useRef();
  const ref = useRef();
  useForceScrollToTop({ scrollRef: contentRef, deps: [location?.pathname] });
  const screenshotPortalProps = usePortal();
  const [screenshotStyle, setScreenshotStyle] = useState("gray");

  return (
    <NavigatorProvider>
      <IFrameProvider>
        <TooltipProvider>
          <ErrorBoundary FallbackComponent={CrashPage} onReset={noop}>
            <div
              className={classNameBuilder("app", ...themeClassNames, {
                screenshot: screenshotPortalProps.isPortalComponentRendered,
                "gray-screenshot":
                  screenshotPortalProps.isPortalComponentRendered &&
                  screenshotStyle === "gray",
              })}
              id="capture2image"
              ref={ref}
            >
              <Helmet>
                <title>Wildsea Digital Character Sheet</title>
                <link
                  rel="icon"
                  type="image/png"
                  href={`/wildsea-character-sheet/favicon-${theme.secondary}.ico`}
                />
              </Helmet>
              <AppHeader />
              <RootContent
                contentRef={contentRef}
                screenshotPortalProps={screenshotPortalProps}
                screenshotStyle={screenshotStyle}
                setScreenshotStyle={setScreenshotStyle}
              />
            </div>
          </ErrorBoundary>
        </TooltipProvider>
      </IFrameProvider>
    </NavigatorProvider>
  );
};
