import { Loader } from "./Loader";
import styles from "./section.module.scss";
import classnames from "classnames/bind";
import { useState } from "react";
import { noop } from "lodash";
import { Chevron } from "./Shape/Chevron";
import { ExpansionLogo } from "./ExpansionLogo";

const classNameBuilder = classnames.bind(styles);

export const Section = ({
  label,
  sublabel,
  headerSuffix,
  children,
  noBorder,
  fill,
  updating,
  className,
  headerClassName,
  contentClassName,
  headerSuffixClassName,
  labelClassName,
  noPadding,
  disabled,
  isSubSection,
  gray,
  expandable,
  initialExpanded,
  fullyCollapsible,
  onExpandChange = noop,
  unexpandedClassName = "unexpanded",
  expandedClassName = "expanded",
  expansion,
  underSection,
}) => {
  const [expanded, setExpanded] = useState(initialExpanded);

  let expandClassName = "";

  if (expandable) {
    expandClassName = expanded ? expandedClassName : unexpandedClassName;
  }

  return (
    <div className={classNameBuilder("container", className)}>
      <div
        onClick={() =>
          expandable &&
          setExpanded((prev) => {
            onExpandChange(!prev);
            return !prev;
          })
        }
        className={classNameBuilder("header", headerClassName, {
          secondary: isSubSection,
          gray,
          disabled,
          expandable,
        })}
      >
        {expandable && (
          <Chevron opened={expanded} className={styles["chevron"]} />
        )}
        <span className={classNameBuilder("label", labelClassName)}>
          {label}
          {sublabel && (
            <span className={classNameBuilder("sublabel")}>{sublabel}</span>
          )}
        </span>
        <div className={styles["suffix"]}>
          {Boolean(updating) && <Loader className={styles["loader"]} />}
          {expansion && !Boolean(updating) && (
            <ExpansionLogo type={expansion} />
          )}
          {headerSuffix && (
            <div
              className={classNameBuilder(
                "header-suffix",
                headerSuffixClassName
              )}
              onClick={(e) => e.stopPropagation()}
            >
              {headerSuffix}
            </div>
          )}
        </div>
      </div>
      <div
        className={classNameBuilder("buffer", {
          borderless: noBorder,
          secondary: isSubSection,
          gray,
          disabled,
          "no-padding": noPadding,
          collapsed: fullyCollapsible && !expanded,
        })}
      />
      {fullyCollapsible && !expanded ? null : (
        <div
          className={classNameBuilder(
            "content",
            contentClassName,
            expandClassName,
            {
              borderless: noBorder,
              secondary: isSubSection,
              gray,
              disabled,
              "no-padding": noPadding,
              fill,
            }
          )}
        >
          {children}
        </div>
      )}
      {underSection}
    </div>
  );
};
