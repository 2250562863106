import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./diceboxContext.module.scss";
import DiceBox from "@3d-dice/dice-box";
import { useTheme } from "./ThemeContext";
import themeColors from "styles/themes.scss";
import { get } from "lodash";
import { CUSTOM_DICE_KEY, DEFAULT_DICE_KEY } from "constants/themes";
import classNames from "classnames/bind";

const classNameBuilder = classNames.bind(styles);

const DiceboxContext = createContext();

const DEFAULT_COLOR = "#FF0000";
const DICE_BOX_ID = "#dice-box";

const getNewDiceBox = (options) => {
  const config = {
    assetPath: "/assets/", // required
    id: "dice-canvas",
    theme: "default",
    themeColor: DEFAULT_COLOR,
    friction: 0.9,
    scale: 5,
    gravity: 5,
    mass: 8,
    linearDamping: 0.6,
    angularDamping: 0.6,
    spinForce: 1,
    throwForce: 30,
    shadowTransparency: 0.6,
    startingHeight: 5,
    ...options,
  };

  return new DiceBox(DICE_BOX_ID, config);
};

export const DiceboxProvider = ({ children }) => {
  const {
    theme: { secondary, dice, customDiceColor },
  } = useTheme();
  let themeColor = get(
    themeColors,
    dice === DEFAULT_DICE_KEY ? secondary : dice,
    DEFAULT_COLOR
  );

  if (dice === CUSTOM_DICE_KEY) {
    themeColor = customDiceColor || DEFAULT_COLOR;
  }

  const [diceBox, setDicebox] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef();

  useEffect(() => {
    setDicebox(getNewDiceBox({ themeColor }));
  }, [themeColor]);

  useEffect(() => {
    if (!diceBox) {
      return;
    }
    diceBox
      ?.init()
      .then(() => {
        diceBox.rollBasic = (...args) => {
          setOverlayVisible(true);

          return diceBox.roll(...args);
        };
      })
      .catch((err) => console.error("failed to init dicebox", err));
  }, [diceBox]);

  const value = useMemo(() => ({ diceBox, overlayRef }), [diceBox]);

  return (
    <DiceboxContext.Provider value={value}>
      <div
        className={classNameBuilder("overlay", { visible: overlayVisible })}
        ref={overlayRef}
        onClick={(e) => {
          e.stopPropagation();
          setOverlayVisible(false);
          diceBox.clear();
        }}
      />
      {children}
    </DiceboxContext.Provider>
  );
};

export const useDicebox = () => {
  const context = useContext(DiceboxContext);
  if (!context) {
    throw new Error("useDicebox must be used within a DiceboxProvider");
  }
  return context;
};
