import React, {
  createContext,
  useState,
  useMemo,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { createPortal } from "react-dom";
import {
  useKeyupListener,
  includesKeys,
} from "../hooks/listeners/useKeyupListener";
import styles from "./portalContext.module.scss";
import classnames from "classnames/bind";
import { useTransition } from "../hooks/transition/useTransition";
import { KEYS } from "../utils/listeners";
import { isNull, every, identity, isNil } from "lodash";

const classNameBuilder = classnames.bind(styles);

const PortalContext = createContext(null);
PortalContext.displayName = "PortalContext";

const baseZIndex = 10;

export const PortalProvider = ({ children }) => {
  const [portalLength, setPortalLength] = useState(0);
  const [locked, setLocked] = useState(false);
  const portalRef = useRef(null);
  const portalOnCloseListenersRef = useRef({});
  const decrementPortalLength = useCallback(
    (override) =>
      (!locked || override === true) &&
      setPortalLength((length) => Math.max(length - 1, 0)),
    [setPortalLength, locked]
  );
  const incrementPortalLength = useCallback(
    (override) =>
      (!locked || override === true) && setPortalLength((length) => length + 1),
    [setPortalLength, locked]
  );
  const resetPortalLength = useCallback(
    (override) => (!locked || override === true) && setPortalLength(0),
    [setPortalLength, locked]
  );

  const value = useMemo(
    () => ({
      decrementPortalLength,
      incrementPortalLength,
      resetPortalLength,
      portalLength,
      portalRef,
      portalOnCloseListenersRef,
      setLocked,
    }),
    [
      portalLength,
      portalRef,
      decrementPortalLength,
      incrementPortalLength,
      resetPortalLength,
      setLocked,
    ]
  );

  const checkCanDecrementPortal = useCallback(async () => {
    const listeners = await Promise.all(
      Object.values(portalOnCloseListenersRef.current)
        .filter(identity)
        .map((cb) => {
          return cb({ portalLength });
        })
    );

    // ensure all promises are truthy values
    return every(listeners, identity);
  }, [portalLength]);

  useKeyupListener(
    async (e) => {
      if (includesKeys(e, KEYS.ESCAPE)) {
        const canDecrement = await checkCanDecrementPortal();
        // check all listeners return a truth-y value. If so, allow the portal to close
        if (canDecrement) {
          value.decrementPortalLength();
        }
      }
    },
    [decrementPortalLength, checkCanDecrementPortal]
  );
  const [maskRef, isMaskRendered, isMaskTransitioning] = useTransition(
    portalLength > 0
  );
  return (
    <PortalContext.Provider value={value}>
      <div ref={portalRef} className={classNameBuilder("portal")}>
        {isMaskRendered ? (
          <div
            ref={maskRef}
            className={classNameBuilder("mask", {
              displayed: isMaskTransitioning,
            })}
            style={{ zIndex: baseZIndex + (portalLength - 1) * 2 - 1 }}
            onClick={async () => {
              const canDecrement = await checkCanDecrementPortal();
              // check all listeners return a truth-y value. If so, allow the portal to close
              if (canDecrement) {
                value.decrementPortalLength();
              }
            }}
          />
        ) : null}
      </div>
      {children}
    </PortalContext.Provider>
  );
};

export const usePortal = ({ onInterceptPortalClose } = {}) => {
  const {
    portalLength,
    incrementPortalLength,
    decrementPortalLength,
    portalOnCloseListenersRef,
    portalRef,
    setLocked,
  } = useContext(PortalContext);
  const [isComponentRendered, setIsComponentRendered] = useState(false);
  const [portalIndex, setPortalIndex] = useState(null);

  const renderComponent = useCallback(() => {
    setIsComponentRendered(true);
    setPortalIndex(portalLength);
  }, [portalLength]);
  const derenderComponent = useCallback(() => {
    setIsComponentRendered(false);
    setPortalIndex(null);
    // cleanup portal on close listener immediately
    delete portalOnCloseListenersRef.current[portalIndex];
  }, [portalIndex, portalOnCloseListenersRef]);

  const setIsPortalComponentRendered = useCallback(
    (value, override) => {
      if (!isComponentRendered && value) {
        renderComponent();
        incrementPortalLength(override);
        return;
      }
      if (isComponentRendered && !value) {
        derenderComponent();
        decrementPortalLength(override);
        return;
      }
    },
    [
      incrementPortalLength,
      decrementPortalLength,
      derenderComponent,
      renderComponent,
      isComponentRendered,
    ]
  );
  const setIsLockedPortalComponentRendered = useCallback(
    (value) => {
      setIsPortalComponentRendered(value, true);
      setLocked(value);
    },
    [setLocked, setIsPortalComponentRendered]
  );
  useEffect(() => {
    if (!isNull(portalIndex) && portalLength <= portalIndex) {
      derenderComponent();
    }
  }, [portalLength, portalIndex, derenderComponent]);

  // add onInterceptPortalClose listener to ref and clean up on unmount
  useEffect(() => {
    if (onInterceptPortalClose && !isNil(portalIndex)) {
      portalOnCloseListenersRef.current[portalIndex] = onInterceptPortalClose;

      return () => {
        if (
          portalOnCloseListenersRef.current[portalIndex] ===
          onInterceptPortalClose
        ) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          delete portalOnCloseListenersRef.current[portalIndex];
        }
      };
    }
  }, [onInterceptPortalClose, portalIndex, portalOnCloseListenersRef]);

  return {
    portalRef,
    setIsPortalComponentRendered,
    isPortalComponentRendered: isComponentRendered,
    portalIndex,
    lockPortal: setLocked,
    setIsLockedPortalComponentRendered,
    setLocked,
  };
};

export const Portal = ({ children, portalProps }) => {
  const { portalRef, portalIndex } = portalProps;
  return portalRef.current
    ? createPortal(
        <div
          className={styles["portal-component"]}
          style={{ zIndex: baseZIndex + portalIndex * 2 }}
        >
          {children}
        </div>,
        portalRef.current
      )
    : null;
};
